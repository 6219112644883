<template>
    <standard-page definition="View or Update a template/definition for DID or Claim" title="Template">
        <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'identities-home'}">Identifier</router-link>
      </span>
            <span><router-link :to="{ name: 'identities-catalogs'}">Catalogs</router-link></span>
            <span v-if="catalogName">CATALOG: {{catalogName | truncate(20, '.....')}}</span>
            <span><router-link :to="{ name: 'templates'}">DID Templates</router-link></span>
            <span>Update DID Template</span>
        </template>
        <template v-slot:content>
            <div class="tab-pane-body">
                <div class="row row-xs align-items-center mg-b-20 pd-30 pd-sm-40 bg-gray-200" v-for="(row, index) in did_template_properties">
                    <div class="col-md-1 mg-t-10 mg-md-t-0" v-if="did_template_properties.length > 1">
                        <span :class="{'hide': index === 0}" @click="up(index, index-1)" class="text-info up mg-r-10" style="cursor: pointer">
                            <i class="fas fa-arrow-up fa-lg" title="Up"></i>
                        </span>
                        <span :class="{'hide': index+1 === did_template_properties.length}" @click="down(index, index+1)" class="text-info down mg-r-10" style="cursor: pointer">
                            <i class="fas fa-arrow-down fa-lg" title="${title}"></i>
                        </span>
                    </div>
                    <div class="col-md-2 mg-t-5 mg-md-t-0">
                        <input :class="{'is-invalid': submitted && errors.has('name'+index)}" :name="'name'+index" class="form-control rounded" data-vv-as="Attribute Name" placeholder="Enter Key"
                               style="height: 42px" type="text" v-model="row.name" v-validate="'required'">
                        <div class="invalid-feedback" v-if="submitted && errors.has('name'+index)">{{ errors.first('name'+index) }}</div>
                    </div>
                    <div class="col-md-2 mg-t-5 mg-md-t-0">
                        <select :class="{'is-invalid': submitted && errors.has('type'+index)}" :name="'type'+index" @change="changeFactType(row)" aria-hidden="true"
                                class="form-control select2-no-search select2-hidden-accessible rounded" data-select2-id="13" data-vv-as="Attribute Type" style="height: 42px" tabindex="-1"
                                v-model="row.fact_type" v-validate="'required'">
                            <option v-bind:value=null>--- Please Select a type ---</option>
                            <option v-bind:key="type" v-for="(type) in data_types">{{type}}</option>
                        </select>
                        <div class="invalid-feedback" v-if="submitted && errors.has('type'+index)">{{ errors.first('type'+index) }}</div>
                    </div>
                    <div class="col-md-1 mg-t-5 mg-md-t-0">
                        <input :name="'options'+index" :readonly="row.fact_type !== 'Text'" class="form-control rounded" placeholder="Options" v-model="row.options">
                    </div>
                    <div class="col-md-2 mg-t-5 mg-md-t-0">
                        <div class="form-check form-check-inline">
                            <input :class="{ 'is-invalid' : submitted && errors.has('required'+index) }" :name="'required'+index" :value="false" class="form-check-input" type="radio"
                                   v-model="row.required" v-validate="'required'">
                            <label class="form-check-label" for="Yes">Not Required</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input :class="{ 'is-invalid' : submitted && errors.has('required'+index) }" :name="'required'+index" :value="true" class="form-check-input" type="radio"
                                   v-model="row.required">
                            <label class="form-check-label" for="No">Required</label>
                        </div>
                        <div class="invalid-feedback" v-if="submitted && errors.has('required'+index)">{{ errors.first('required'+index) }}</div>
                    </div>
                    <div class="col-md-2 mg-t-5 mg-md-t-0">
                        <div class="form-check form-check-inline">
                            <input :class="{ 'is-invalid' : submitted && errors.has('private'+index) }" :disabled="templatetype === 'Claim'" :name="'private'+index" :value="false"
                                   class="form-check-input" type="radio"
                                   v-model="row.private">
                            <label class="form-check-label" for="No">Public</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input :class="{ 'is-invalid' : submitted && errors.has('private'+index) }" :disabled="templatetype === 'Claim'" :name="'private'+index" :value="true"
                                   class="form-check-input" type="radio"
                                   v-model="row.private">
                            <label class="form-check-label" for="Yes">Private</label>
                        </div>
                        <div class="invalid-feedback" v-if="submitted && errors.has('private'+index)">{{ errors.first('private'+index) }}</div>
                    </div>
                    <div class="col-md-1 mg-t-10 mg-md-t-0" v-if="did_template_properties.length > 1">
                        <a @click="removeRow(index)" class="btn btn-block btn-danger rounded" href="javascript:void(0)"><i class="fa fa-trash-alt"></i></a>
                    </div>
                    <div class="col-md-1 mg-t-10 mg-md-t-0">
                        <a @click="addRow" class="btn btn-block btn-success rounded" href="javascript:void(0)" type="button" v-if="index === did_template_properties.length-1"><i
                                class="fa fa-plus-square"></i></a>
                    </div>
                </div>
            </div>
            <div class="row row-sm mg-t-20">
                <div class="col-lg-4 mg-t-5 offset-lg-8">
                    <button @click="updateTemplate" class="btn btn-primary w-100 rounded font-weight-bold" type="button"> Update
                    </button>
                </div>
            </div>
        </template>
    </standard-page>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "updateTemplate",
        props: ['catalogid', 'catalogNameProp', 'templateid', 'templatetype'],
        computed: {
            ...mapGetters(["currentUser", "currentOrg"])
        },
        data() {
            return {
                catalogName: this.catalogNameProp,
                submitted: false,
                data_types: ['Bool', 'Text', 'Did', 'Integer', 'Float', 'Date', 'DateTime', 'File', 'Location'],
                did_template_properties: [
                    {
                        name: null,
                        fact_type: "Text",
                        required: true,
                        private: true,
                        property_group: null,
                        repeatable: false,
                    }
                ],
                update_did_template: {
                    add_properties: [],
                    remove_properties: []
                }
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            async init() {
                await Promise.all([
                    this.getCatalog(),
                    this.getDidTemplate()
                ])
            },
            async getCatalog() {
                try {
                    let reply = await this.$identity.get(`/identity/catalogs/${this.catalogid}`);
                    this.catalogName = reply.data ? reply.data.name : null;
                } catch (e) {
                    this.$toastr.e("Error getting catalog name", "Error");
                }
            },
            async getDidTemplate() {
                try {
                    let {data} = await this.$identity.get(`identity/catalogs/${this.catalogid}/templates/${this.templateid}`);
                    this.did_template_properties = data;
                    this.update_did_template.remove_properties = data.filter(tp=> tp.template_property);
                } catch (e) {

                }
            },
            addRow() {
                this.did_template_properties.push({
                    name: null,
                    type: "Text",
                    required: true,
                    private: true
                });
            },
            removeRow(index) {
                this.did_template_properties.splice(index, 1);
            },
            changeFactType(row) {
                if (row.fact_type !== 'Text') {
                    row.options = null;
                }
            },
            up(index, prev_index) {
                if (prev_index > -1) {
                    this.did_template_properties[index] = this.did_template_properties.splice(prev_index, 1, this.did_template_properties[index])[0];
                }
            },
            down(index, next_index) {
                if (next_index < this.did_template_properties.length) {
                    this.did_template_properties[index] = this.did_template_properties.splice(next_index, 1, this.did_template_properties[index])[0];
                }
            },
            async updateTemplate() {
                this.update_did_template.add_properties = this.did_template_properties//.filter(p => typeof p.template_property === 'undefined');
                if (this.update_did_template.template_type === 'Claim') {
                    this.update_did_template.add_properties = this.update_did_template.add_properties.map(p => ({
                        ...p,
                        private: false
                    }))
                }
                try {
                    await this.$identity.patch(`/identity/catalogs/${this.catalogid}/templates/${this.templateid}`, this.update_did_template);
                    this.$toastr.s("DID template successfully updated", "Success");
                    await this.getDidTemplate();
                } catch (e) {
                    this.$toastr.e("Error updating DID template", "Error");
                } finally {

                }
            },
        }
    }
</script>

<style scoped>
    .hide {
        display: none; }
</style>